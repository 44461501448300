<template>
  <el-row :gutter="40" v-loading="loading">
    <el-col :span="10">
      <div class="flex-def flex-cEnd" style="width: 24rem;min-height: 18rem">
        <template v-for="(item,key) in form.list">
          <div  :key="key" class="menu-itm y-pointer y-desc flex-def flex-cCenter flex-zCenter">
            <div class="menu-sub-box">
              <div :class="active_key === key && active_child === k ? 'menu-active' : ''" v-for="(v,k) in item.sub_button" :key="k" @click="activeChoose(key,k)" class="menu-sub-itm">{{ v.name }}</div>
              <div v-if="item.sub_button.length < 5" @click="addMenuSub(key)" class="menu-sub-itm">+子菜单项</div>
            </div>
            <div :class="active_key === key && active_child === false ? 'menu-active' : ''" @click="activeChoose(key)" style="width: 100%">{{item.name}}</div>
          </div>
        </template>
        <div @click="addMenu" v-if="form.list.length < 3" class="menu-itm y-pointer y-desc flex-def flex-cCenter flex-zCenter">+添加菜单项</div>
      </div>
    </el-col>
    <el-col :span="12">
      <el-button v-if="is_open" style="width: 100%;margin-bottom: 1rem" @click="del" size="small" type="danger">删除自定义菜单</el-button>
      <div v-if="!is_open && form.list.length===0" class="flex-def flex-zCenter flex-cCenter" style="font-weight: 600;color: #888888;height: 18rem">
        未开启自定义菜单 点击左侧添加菜单项开始配置
      </div>
      <el-card v-if="active_key !== false && (is_open || form.list.length > 0)" style="min-height: 15rem">
        <el-form :model="form" label-width="6rem">
          <template v-if="active_child === false">
            <el-form-item label="菜单项名称">
              <el-input v-model="form.list[active_key].name"></el-input>
            </el-form-item>
            <el-form-item v-if="form.list[active_key].sub_button.length === 0" label="菜单链接">
              <el-input v-model="form.list[active_key].url"></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="菜单项名称">
              <el-input v-model="form.list[active_key].sub_button[active_child].name"></el-input>
            </el-form-item>
            <el-form-item label="菜单链接">
              <el-input v-model="form.list[active_key].sub_button[active_child].url"></el-input>
            </el-form-item>
          </template>
          <el-form-item >
            <el-button @click="activeRemove">移除菜单项</el-button>
            <el-button @click="submit" type="primary">发布菜单</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "menus",
  components:{
  },
  data(){
    return{
      loading:false,
      form:{
        list:[]
      },
      active_key:false,
      active_child:false,
      is_open:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    del(){
      this.$api.channel.wxofficialMenuDel().then(()=>{
        this.load();
      })
    },
    activeRemove(){
      if (this.active_child !== false){
        this.form.list[this.active_key].sub_button.splice(this.active_child,1);
      }else {
        this.form.list.splice(this.active_key,1);
      }
      this.activeClose();
    },
    addMenuSub(key){
      let len = this.form.list[key].sub_button.push({
        name:"子菜单名称",
        url:""
      })
      this.active_key = key;
      this.active_child = len-1;
    },
    addMenu(){
      let len = this.form.list.push({
        name:"菜单名称",
        url:"",
        sub_button:[]
      })
      this.activeChoose(len-1)
    },
    activeChoose(key,child=false){
      this.active_key = key;
      this.active_child = child;
    },
    activeClose(){
      this.active_key = false;
      this.active_child = false;
      if(this.form.list.length > 0){
        this.activeChoose(0)
      }
    },
    load(){
      this.loading = true;
      this.$api.channel.wxofficialMenuInfo().then(res=>{
        this.loading = false;
        res.forEach((item,key)=>{
          if(!item.sub_button){
            res[key].sub_button = []
          }
          if (!item.url){
            res[key].url = ""
          }
        })
        this.form.list = res;
        if (res.length > 0){
          this.activeChoose(0)
        }
        this.is_open = true;
      }).catch(()=>{
        this.loading = false;
        this.is_open = false;
        this.form.list = [];
      })
    },
    submit(){
      if (this.form.list.length === 0){
        this.$message.error("未设置菜单项");
        return
      }
      try {
        this.form.list.forEach((item,key)=>{
          if(item.name === ""){
            this.$message.error("菜单项名称不能为空");
            this.activeChoose(key);
            throw "err"
          }
          if(item.sub_button.length > 0){
            item.sub_button.forEach((v,k)=>{
              if(v.name === ""){
                this.$message.error("菜单项名称不能为空");
                this.activeChoose(key,k);
                throw "err"
              }
              if(v.url === ""){
                this.$message.error("菜单项链接不能为空");
                this.activeChoose(key,k)
                throw "err"
              }
            })
          }else {
            if(item.url === ""){
              this.$message.error("菜单项链接不能为空");
              this.activeChoose(key)
              throw "err"
            }
          }
        })
        this.$api.channel.wxofficialMenuEdit(this.form).then(()=>{
          this.$message.success("操作成功");
          this.load();
        })
      }catch (e) {
       console.log(e);
      }
    }
  }
}
</script>

<style scoped>
.menu-sub-box{
  position: absolute;
  bottom: 3rem;
  width: 100%;
  z-index: 1;
}
.menu-sub-itm,.menu-itm{
  background-color: #f8f8f8;
  text-align: center;
  line-height: 3rem;
  height: 3rem;
  width: 100%;
}
.menu-sub-itm{
  border-bottom: 1px solid #ededed;
}
.menu-active{
  background-color: #5D60F6;
  color: #FFFFFF;
}
.menu-itm{
  position: relative;
  border-left: 1px solid #ededed;
}
.menu-itm:first-child{
  border-left: unset;
}
</style>