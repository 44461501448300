<template>
  <div>
    <YTabs v-model="active_tab" active-line>
      <YTabPane label="公共链接" name="entry">
        <el-card>
          <entry></entry>
        </el-card>
      </YTabPane>
      <YTabPane label="炮灰域名" name="ph">
        <el-card><ph></ph></el-card>
      </YTabPane>
      <YTabPane label="接入配置" name="wait">
        <el-card><conf v-if="active_tab == 'wait'"></conf></el-card>
      </YTabPane>
      <YTabPane label="js验证文件" name="js">
        <el-card><js></js></el-card>
      </YTabPane>
      <YTabPane label="自定义菜单" name="menu">
        <el-card><menus v-if="active_tab === 'menu'"></menus></el-card>
      </YTabPane>
      <YTabPane label="消息通知" name="notice">
        <el-card><notice></notice></el-card>
      </YTabPane>
    </YTabs>
<!--    <el-tabs v-model="active_tab" type="border-card" style="min-height: 35rem">-->
<!--      <el-tab-pane label="公共链接" name="entry">-->
<!--        <entry></entry>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="炮灰域名" name="ph">-->
<!--        <ph></ph>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="接入配置" name="wait">-->
<!--        <conf v-if="active_tab == 'wait'"></conf>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="js验证文件" name="js">-->
<!--        <js></js>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="自定义菜单" name="menu">-->
<!--        <menus v-if="active_tab === 'menu'"></menus>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="消息通知" name="notice">-->
<!--        <notice></notice>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
  </div>
</template>

<script>
import conf from "@/view/channel/app/conf";
import ph from "@/view/channel/app/ph";
import entry from "@/view/channel/app/entry";
import menus from "@/view/channel/app/menu";
import notice from "@/view/channel/app/notice";
import js from "@/view/channel/app/js";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "index",
  components:{
    YTabPane,
    YTabs,
    conf,
    ph,
    entry,
    menus,
    notice,
    js
  },
  data(){
    return{
      active_tab:"entry"
    }
  }
}
</script>

<style scoped>

</style>