<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <el-form v-loading="loading" :model="form" label-width="8rem">
        <el-form-item label="公众号名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="公众号类型">
          仅认证服务号可用
        </el-form-item>
        <el-form-item label="AppID">
          <el-input v-model="form.app_id"></el-input>
        </el-form-item>
        <el-form-item label="AppSecret">
          <el-input v-model="form.app_secret" ></el-input>
        </el-form-item>
        <el-form-item label="公众号二维码">
          <y_upload_img v-model="form.qrcode"></y_upload_img>
        </el-form-item>
        <el-form-item >
          <el-button @click="edit" type="primary">接入检测</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col v-if="form.id" :span="12">
      <div class=" y-pointer">
        <span style="font-size: 14px;color: #606266;">接入状态：</span>
        <el-tag effect="dark" size="mini" :type="form.is_connect ? 'success' : 'info'">
          {{form.is_connect ? '接入正常' : '未接入'}}
        </el-tag>
      </div>
      <div style="margin-top: 2rem;font-size: 14px;margin-bottom: .5rem;font-weight: 600;color: #888888">公众号通信配置</div>
      <el-form style="min-width: 32rem;max-width: 35rem" label-width="8rem" label-position="left">
        <el-form-item>
          <template #label>
            <div style="line-height: 1rem">
              <span>URL</span>
              <br>
              <span>(服务器地址)</span>
            </div>
          </template>
          <div class="flex-def flex-zBetween">
            <span>{{connect_url}}</span>
            <el-button @click="copy(connect_url)" size="mini" type="text">点击复制</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div style="line-height: 1rem">
              <span>Token(令牌)</span>
              <br>
              <span>{{form.token}}</span>
            </div>
          </template>
          <div class="flex-def flex-zEnd">
            <div>
              <el-button @click="tokenEdit" size="mini" type="text">修改</el-button>
              <el-button @click="tokenMake" size="mini" type="text">生成新的</el-button>
              <el-button @click="copy(form.token)" size="mini" type="text">点击复制</el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label-width="15rem">
          <template #label>
            <div style="line-height: 1rem">
              <span>EncodingAESKey(消息加解密密钥)</span>
              <br>
              <span>{{ form.encoding_aes_key }}</span>
            </div>
          </template>
          <div class="flex-def flex-zBetween">
            <span></span>
            <div>
              <el-button @click="enEdit" size="mini" type="text">修改</el-button>
              <el-button @click="enMake" size="mini" type="text">生成新的</el-button>
              <el-button @click="copy(form.encoding_aes_key)" size="mini" type="text">点击复制</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-col>

    <el-dialog title="修改token" :visible.sync="tokenFormShow" @close="connectEditClose">
      <el-form :model="connectEditForm" label-width="0">
        <el-form-item>
          <el-input v-model="connectEditForm.token" placeholder="请填写新的公众号消息校验token" autocomplete="off"></el-input>
          <div class="y-desc" style="line-height: 1rem;margin-top: 1rem">与公众号接入设置值一致，必须为英文或者数字，长度为3到32个字符</div>
          <div class="y-desc" style="line-height: 1rem">请妥善保管, Token 泄露将可能被窃取或篡改平台的操作数据.</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="tokenFormShow = false">取 消</el-button>
        <el-button type="primary" @click="connectEditSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改EncodingAESKey" :visible.sync="enFormShow" @close="connectEditClose">
      <el-form :model="connectEditForm" label-width="0">
        <el-form-item>
          <el-input v-model="connectEditForm.encoding_aes_key" placeholder="请填写新的公众号消息加密key" autocomplete="off"></el-input>
          <div class="y-desc" style="line-height: 1rem;margin-top: 1rem">与公众号接入设置值一致，必须为英文或者数字，长度为43个字符</div>
          <div class="y-desc" style="line-height: 1rem">请妥善保管, EncodingAESKey 泄露将可能被窃取或篡改平台的操作数据.</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="enFormShow = false">取 消</el-button>
        <el-button type="primary" @click="connectEditSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
import y_upload_img from "@/components/y_upload_img";
export default {
  name: "conf",
  components:{
    y_upload_img,
  },
  data(){
    return{
      form:{
        is_connect:false,
        name:"",
        app_id:"",
        app_secret:"",
        qrcode:"",
        token:"",
        encoding_aes_key:"",
      },
      connect_url:"",
      tokenFormShow:false,
      enFormShow:false,
      connectEditForm:{
        token:"",
        encoding_aes_key:"",
        tap:""
      },
      loading:false
    }
  },
  mounted() {
    this.connect_url = location.origin + `/notify/${this.$route.params.uni_acid}/official`
    this.load();
  },
  methods:{
    connectEditClose(){
      this.connectEditForm = {
        token:"",
        encoding_aes_key:"",
        tap:""
      }
      this.tokenFormShow = false;
      this.enFormShow = false;
    },
    connectEditSubmit(){
      this.$api.channel.wxofficialConnectEdit(this.connectEditForm).then(()=>{
        this.$message.success("操作成功");
        this.connectEditClose();
        this.load();
      })
    },
    tokenMake(){
      this.connectEditForm.tap = "makeToken";
      this.connectEditSubmit();
    },
    enMake(){
      this.connectEditForm.tap = "makeEn";
      this.connectEditSubmit();
    },
    tokenEdit(){
      this.tokenFormShow = true;
      this.connectEditForm.tap = "edit";
    },
    enEdit(){
      this.enFormShow = true;
      this.connectEditForm.tap = "edit";
    },
    copy(str){
      this.$copyText(str).then(()=>{
        this.$message.success("复制成功");
      })
    },
    load(){
      this.loading = true;
      this.$api.channel.wxofficialInfo().then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    edit(){
      this.$api.channel.wxofficialEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
</style>