<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <y_upload_js></y_upload_js>
    </el-col>
  </el-row>
</template>

<script>
import y_upload_js from "@/components/y_upload_js";
export default {
  name: "js",
  components:{
    y_upload_js,
  },
  data(){
    return{
      js:false,
    }
  },
  mounted() {

  },
  methods:{

  },
  computed:{

  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
</style>