<template>
  <div>
    <el-card shadow="hover">
      <div class="flex-def flex-zTopBottom y-desc" style="margin-left: 10rem;font-weight: 600">
        <span>1. 活动域名、炮灰域名需要解析到当前服务器，以能打开当前平台为准</span>
        <span>2. 公众平台：js安全域名填活动域名、炮灰域名， 网页授权域名业务域名填炮灰域名(建议都填写)</span>
        <span>3. 微信支付平台：产品中心-开发配置：支付目录填写炮灰域名</span>
        <span>4. 活动域名和炮灰域名下方填写完整：前缀http或https和后缀/</span>
        <span>例如：https://www.baidu.com/</span>
      </div>
    </el-card>
    <el-form :model="form" label-width="8rem" style="padding: 2rem">
      <el-form-item label="活动域名">
        <el-input v-model="form.domain_active" style="width: 30rem"></el-input>
      </el-form-item>
      <el-form-item label="炮灰域名">
        <el-input v-model="form.domain_ph" style="width: 30rem"></el-input>
      </el-form-item>
      <el-form-item >
        <el-button @click="edit" type="primary">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "ph",
  data(){
    return{
      form:{
        domain_ph:"",
        domain_active:"",
      },
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$api.channel.wxofficialInfo().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$api.channel.wxofficialEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>

</style>