<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <el-form :model="form" label-width="8rem">
        <el-form-item label="购买成功通知">
          <el-input v-model="form.official_buy"></el-input>
          <div class="y-desc">IT科技 - 互联网|电子商务:OPENTM411768652</div>
        </el-form-item>
        <el-form-item label="卡券到期前提醒">
          <el-input v-model="form.official_coupon_lose"></el-input>
          <div class="y-desc">IT科技 - 互联网|电子商务: 资质到期提醒 OPENTM410515010</div>
        </el-form-item>
        <el-form-item label="新订单通知">
          <el-input v-model="form.official_new_order"></el-input>
          <div class="y-desc">IT科技 - 互联网|电子商务:TM00351</div>
        </el-form-item>
        <el-form-item label="抽奖结果通知">
          <el-input v-model="form.official_luck_draw"></el-input>
          <div class="y-desc">IT科技 - 互联网|电子商务:OPENTM412181311</div>
        </el-form-item>
        <el-form-item label="成员加入提醒">
          <el-input v-model="form.official_join"></el-input>
          <div class="y-desc">IT科技 - 互联网|电子商务:OPENTM207685059</div>
        </el-form-item>
        <el-form-item label="核销成功通知">
          <el-input v-model="form.official_cashier" ></el-input>
          <div class="y-desc">IT科技 - 互联网|电子商务:OPENTM405896004</div>
        </el-form-item>
        <el-form-item >
          <el-button @click="edit" type="primary">提交</el-button>
        </el-form-item>
      </el-form>
    </el-col>
<!--    <el-col :span="12">-->
<!--      <div style="text-align: center;margin-bottom: 1rem;font-size: 1rem" class="y-desc">阿里短信服务</div>-->
<!--      <el-form :model="form" label-width="8rem">-->
<!--        <el-form-item label="accessKeyId">-->
<!--          <el-input v-model="form.name2"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="accessKeySecret">-->
<!--          <el-input v-model="form.name3"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="短信签名名称">-->
<!--          <el-input v-model="form.app_id2"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="短信模版id">-->
<!--          <el-input v-model="form.app_secret2" ></el-input>-->
<!--          <div class="y-desc">模版配置示例：验证码${number}，打死不告诉别人！</div>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="测试">-->
<!--          <el-input v-model="form.app_secret3" placeholder="输入测试手机号">-->
<!--            <template #append>-->
<!--              <el-button type="primary">发送</el-button>-->
<!--            </template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-col>-->
  </el-row>
</template>

<script>
export default {
  name: "conf",
  components:{
  },
  data(){
    return{
      form:{
        official_buy:"",
        official_coupon_lose:"",
        official_new_order:"",
        official_luck_draw:"",
        official_join:"",
        official_cashier:""
      },
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$api.channel.noticeTplOne().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$api.channel.noticeTplUpdate(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
</style>