<template>
  <div>
    <el-input disabled value="上传js验证文件">
      <template slot="append">
        <el-upload
            ref="uploader"
            :action="action"
            :headers="{ytoken:$store.state.user.token}"
            name="file"
            :show-file-list="false"
            accept=".txt"
            :on-success="success"
            :on-error="error"
            :limit="1"
        >
          <el-button type="primary">选择验证文件</el-button>
        </el-upload>
      </template>
    </el-input>
  </div>
</template>

<script>

import config from "@/api/config";
import {uriFullUniacid} from "@/api";

export default {
  name: "y_upload_cert",
  data() {
    return {
      action:"",
    }
  },
  mounted() {
    this.action = this.axios.defaults.baseURL + config.api_base + uriFullUniacid() + this.$api.attach.js;
  },
  methods:{
    success(){
      this.$refs.uploader.clearFiles();
      this.$message.success("上传成功");
    },
    error(e){
      console.log(e)
    },
    handleClose(done){
      done();
    },
  }
}
</script>
<style scoped>

</style>
<style>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}
</style>